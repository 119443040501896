.sensor-panel-container {
    transform: translate(-500px, 330px);
    min-width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    &.visible {
        transform: translate(120px,330px);
    }
}
