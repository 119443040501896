.options {
	color: white;
	display: grid;
	grid-template-columns: 25% 25% 50%;	
	margin-left: 30px;
}

.formControl {
	margin: 20px;
	color: white;
}

.select {
	width: auto;
	color: white;
	margin-right: 10px;
}

option {
	font-family: Mukta Mahee;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #fff !important;
}

.datesRange {
	font-family: Mukta Mahee;
	font-size: 21px;
	font-weight: bold;
}

.selectText {
	font-family: Mukta Mahee;
	font-size: 14px;
	color: white;
	font-weight: normal;
}
