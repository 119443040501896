.dashboard {
	background-color: #414c60;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.options {
	margin-left: 30px;
}

.charts {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
