.app {
  display:flex;
  height:100vh;
  overflow:hidden;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.p-growl.p-component.p-growl-bottomleft {
  left: 130px;
  bottom: 40px;
  top: auto;
  height: 100px;
  width: 300px;
}


@keyframes blink {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
