.status-item {
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.oval {
  width: 0.73em;
  height: 0.73em;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  margin: 0 5px;
}
