@import "./styles/icons";
@import "./styles/variables";

html {
    font-size: 20px;
    height: 100vh;
    overflow: hidden;
}
body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Montserrat, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
	list-style: none;
}

ul{
	padding:0;
	margin:0;
}

h1,h2,h3,h4,h5,h6 {
    padding:0;
    margin:0;
}

.gradient-text {
    background: -webkit-linear-gradient(map-get($colors, light-gradient), map-get($colors, dark-gradient));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.is-active {
	.gradient-text {
		background: -webkit-linear-gradient(white, map-get( $colors, light-gradient));
		background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
	}
}

.panel-container {
    transition: 0.5s;
    position: absolute;
    z-index: 5000;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5), 0 0 2px 0 rgba(0, 0, 0, 0.5);
    background: map-get($colors, main);
    cursor: pointer;

    &.visible {
        z-index: 10000;
        top:0;
    }
}
