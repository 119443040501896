@import "../../../styles/variables";

.sensor-display {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	border-bottom: 1px solid map-get($colors, panel-border);
	padding: 12px 24px 24px 24px;
	box-sizing: border-box;
	&:last-child {
		border-bottom: none;
	}
	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		h2 {
			margin: 0;
			font-size: map-get($fonts, large);
			font-weight: normal;
		}
		margin-bottom: 15px;
	}
	.sensor-display-flags {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.display-box {
			border-radius: 8px;
			box-shadow: 0 0 2px 1px white;
			background: map-get($colors, main-dark);
			width: 65px;
			height: 65px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 75px;
            overflow: hidden;
            box-sizing: border-box;
            opacity: 0.7;
			> span {
				transform: translate(10px, 0);
				font-weight: normal;
				&.icon-track-dots {
					transform: translate(10px, 0px);
					font-size: 65px;
				}
			}
			&.disabled {
				opacity: 0.2;
			}
        }
        &.visible {
            .display-box:not(.disabled){
                opacity: 1;
            }
        }
		&.visible.dots {
			.toggle-dots {
				border: 3px solid map-get($colors, secondary);
				box-shadow: none;
			}
		}
		&.visible.straight {
			.toggle-straight {
				border: 3px solid map-get($colors, secondary);
				box-shadow: none;
			}
		}
		&.visible.gradient {
			.toggle-gradient {
				border: 3px solid map-get($colors, secondary);
				box-shadow: none;
			}
		}
		&.visible.header-heat-map {
			.toggle-header-heat-map {
				border: 3px solid map-get($colors, secondary);
				box-shadow: none;
			}
		}
	}

	.heat-map-icon-wrapper {
		border-radius: 50%;
		width: 1px;
		height: 1px;
		box-shadow: 0 0 16px 10px white;
		font-size: 50px;
		@extend %center;
	}
}
