@import '../../../styles/variables';
$breakpoint-mobile: 400px;

.menu {
	width: $mainMenuWidth;
	color: white;
	@media (max-height: $breakpoint-mobile) {
		width: 40px;
	}
}

.menu-container {
	position: relative;
	z-index: 10000;
	height: 100vh;
	background: map-get($colors, main);
	box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.5), 0 0 16px 0 rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;

	ul {
		display: flex;
        flex-direction: column;
        align-items: center;
        padding-top:10px;
        width: 100%;
        li.menu-item {
            width:100%;
            box-sizing: border-box;
			display: flex;
			align-items: center;
            justify-content: center;
            padding: 10px 10px;
			cursor: pointer;
            height: 80px;
			@media (max-height: $breakpoint-mobile) {
				height: 40px;
			}
			img {
				width: 62px;
				height: 62px;
				@media (max-height: $breakpoint-mobile) {
					width: 34px;
					height: 34px;
				}
			}
			&.selected {
				background: map-get($colors, main-dark);
            }
            .icon-sensors {
                font-size: 42px;
            }
            .icon-maps {
                height: 42px;
				width: 42px;
            }
			.icon-layers {
				font-size: 42px;
			}

        }
        li.selected + li.border {
            display:none;
			margin-top: 5px;
        }
        li.border {
            width:20px;
            height:1px;
            background:map-get($colors, main-dark);
        }
	}

	.audio-button {
		cursor: pointer;
		color: white;
		position: absolute;
		bottom: 100px;
		width: calc(#{$mainMenuWidth} - 24px);
		height: calc(#{$mainMenuWidth} - 24px);
		img {
			width:100%;
		}
	}
}

.panels {
	position: relative;
	height: 0;
    width: 0;
    z-index: 10;
}
