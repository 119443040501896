.MuiPaper-root {
    background-color: #2e3a4e !important;
}

.MuiTypography-colorTextSecondary {
    color: white !important;
}

.MuiButton-textPrimary {
    color: #ffffff !important;
}
.MuiPickersCalendarHeader-iconButton {
    background-color: transparent !important;
}

.MuiPickersDay-daySelected{
    border: 2px solid white !important;
    background-color: transparent !important;
}

.MuiButton-textPrimary:hover {
    background-color: #488ce6 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #131c2c !important;
}

.MuiIconButton-colorSecondary:hover {
    background-color: #488ce622 !important;
}

.MuiTypography-root {
    color: white !important;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: #488ce622 !important;
}

.MuiListItem-button:hover {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.MuiPickersClockNumber-clockNumberSelected {
    color: #2B3648 !important;
}

.date-time-picker-filter .MuiInputBase-input{
    color: white;
    font-size: 14px;
    font-weight: normal;
}

.filterbar-form-control-style-override .MuiSelect-selectMenu{
    color: white;
    font-size: 14px;
    font-weight: normal;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: white !important;
}

.MuiRadio-root {
    color: white !important;
}

.MuiMenuItem-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
video::-webkit-media-controls-fullscreen-button {
    display: none;
}

video::-webkit-media-controls-volume-slider {
    display: none;
}

video::-webkit-media-controls-mute-button {
    display: none;
}
