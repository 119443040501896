.body {
	align-self: flex-start;
	display: grid;
	width: calc(100vw - 60px);
	height: calc(100vh - 170px);
	grid-template-columns: 100%; 
	grid-template-rows: 1fr 1fr 1fr;
	gap: 15px;
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 15px;
}

.chart {
	background-color: #d8d8d8;
	border: 1px #979797;
}
