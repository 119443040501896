@import "../../../styles/variables";

.create-new-layer-panel-container {
    transform: translate(-500px, 175px);
    min-width: 410px;
    height: 250px;
    display: flex;
    flex-direction: column;

    &.visible {
        transform: translate(120px,175px);
    }

    .create-new-layer-header {
        cursor: none;
        height: 60px;
        display:flex;
        flex-direction: row;
        align-items: center;
        background-color: #485669;
        .title-box {
            display:flex;
            margin-left: 30px;
            align-items: center;
            .title {
                font-size: map-get($fonts, small);
                font-weight: normal;
                text-align: left;
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
        .x-icon-box {
            display:flex;
            cursor: pointer;
            margin-left: auto;
            height: 100%;
            align-items: center;
            padding-left: 30px;
            .x-icon {
                width: 12px;
                height: 12px;
                margin-right: 30px;
            }
        }
    }

    .create-new-layer-container {
        margin: 20px 30px;
        display:flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .create-new-layer-input {
        align-items: flex-start;
        width: 100%;
        cursor: pointer;
        height: 60px;
        .p-inputtext {
            width: 50%;
            color: #ffffff;
            border: 0 solid rgba(0,0,0,0);
            background: rgba(0,0,0,0);
            padding: 0.3em;
        }
        .title-line {
            width: 50%;
            height: 1px;
            border: solid 0.5px #81a6e7;
            margin-top: 10px;
        }

    }

    .create-new-layer-footer {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        cursor: none;

        .create-new-layer-footer-row {
            width: 100%;
            height: 60px;
            cursor: not-allowed;
            display: flex;
            align-items: center;
            background-color: #33425d;
            border-radius: 4px;
            .create-new-layer-button {
              background-color: #33425d;
              border: none;
              width: 100%;
              font-size: map-get($fonts, regular);
              font-weight: normal;
              text-align: left;
              padding-left: 15px;

              &.disabled {
                opacity: 0.5;
              }
              &.enabled {
                cursor: pointer;
                border: solid 2px #81a6e7;
                background-color: #465b82;
              }
            }

        }
    }
}
