@import '../../../../styles/variables';

.status-bar {
  position: absolute;
  bottom: 0;
  left: $mainMenuWidth;

  ul {
    z-index: 5000;
    height: 36px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background: rgba(37, 51, 75, 0.8);
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-around;

    li {
      padding:0.5em 5px;
      margin: 0 5px;
      font-size: 1em;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #d0d0d0;
    }
  }
}
