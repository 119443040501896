@font-face {
  font-family: 'icomoon';
  src: url("./assets/fonts/icomoon.eot?v5sgtj");
  src: url("./assets/fonts/icomoon.eot?v5sgtj#iefix") format("embedded-opentype"), url("./assets/fonts/icomoon.ttf?v5sgtj") format("truetype"), url("./assets/fonts/icomoon.woff?v5sgtj") format("woff"), url("./assets/fonts/icomoon.svg?v5sgtj#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* plus */
.icon-plus:before {
  content: "\e902"; }

/* reload */
.icon-reload:before {
  content: "\e90a"; }

/* re-center" */
.icon-re-center:before {
  content: "\e90b"; }

/* align-north */
.icon-align-north:before {
  content: "\e90c"; }

/* minus */
.icon-minus:before {
  content: "\e90d"; }

/* sensors */
.icon-sensors:before {
  content: "\e90f"; }

/* track-gradient */
.icon-track-gradient:before {
  content: "\e904"; }

/* track-line */
.icon-track-straight:before {
  content: "\e905"; }

.icon-heatmap-cursor .path1:before {
  content: "\e907"; }

.icon-heatmap-cursor .path2:before {
  content: "\e908"; }

/* track-dots */
.icon-track-dots:before {
  content: "\e909"; }

/* settings */
.icon-settings:before {
  content: "\e911"; }

/* commands */
.icon-commands:before {
  content: "\e912"; }

/* bookmark */
.icon-bookmark:before {
  content: "\e913"; }

/* video */
.icon-video:before {
  content: "\e914"; }

/* search */
.icon-search:before {
  content: "\e915"; }

/* layers */
.icon-layers:before {
  content: "\e916"; }

html {
  font-size: 20px;
  height: 100vh;
  overflow: hidden; }

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: Montserrat, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

li {
  list-style: none; }

ul {
  padding: 0;
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0; }

.gradient-text {
  background: -webkit-linear-gradient(#a3f1ff, #00bbf7);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.is-active .gradient-text {
  background: -webkit-linear-gradient(white, #a3f1ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.panel-container {
  transition: 0.5s;
  position: absolute;
  z-index: 5000;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5), 0 0 2px 0 rgba(0, 0, 0, 0.5);
  background: #25334b;
  cursor: pointer; }
  .panel-container.visible {
    z-index: 10000;
    top: 0; }
