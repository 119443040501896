.map-parent{
    width:calc(100%);
    box-sizing: content-box;
    height: 100vh;
}
#map-container{
    position: relative;
	margin:0;
    width:100%;
    height: 100%;
    background-color: #0c1334;
}
