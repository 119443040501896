.content-section implementation p-fluid {

  .p-growl {
    position: fixed;
    width: 20em;
    z-index: 10000;
  }


  .growl-bottomleft {
    left: 130px;
    bottom: 40px;
    top: auto;
    height: 100px;
  }
}

