.navigator {
	background-color: #414c60;
	display: flex;
}

.navigation {
	height: 60px;
	background-color: #414c60;
	display: grid;
	grid-template-columns: 45% 45% 10%;
	flex-grow: 1;
}

.gridItem {
	font-size: 20px;
	text-align: center;
	color: #ffffff;
	font-family: Mukta Mahee;
	background: #414c60;
	border-color: transparent;
}

.indicator {
	width: calc(100% + 45px);
	height: 3px;
	margin-top: 16px;
	margin-left: -45px;
	background-image: linear-gradient(
		to right,
		#3272b5,
		#691bbe 46%,
		#ac317f 98%
	);
}

.titleContent {
	display: block;
	margin-top: 2%;
	margin-bottom: 2%;
	margin-left: -10px;
}

.title {
	width: 158px;
	height: 40px;
	margin-top: -60px;
	font-family: Mukta Mahee;
	margin-left: 60px;
	font-size: 24px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: rgba(255, 255, 255, 0.85);
}

.text {
	width: 7rem;
	height: 27px;
	margin-top: -1.5vh;
	margin-left: 50px;
	font-family: Mukta Mahee;
	font-size: 16px;
	font-weight: 200;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: rgba(255, 255, 255, 0.85);
}

.titleBackground {
	width: 224px;
	height: 60px;
	padding: 7px 170px 7px 14px;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
		0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
	background-color: #343d4e;
	clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 0% 100%);
}

.ceptionLogo {
	max-width: 100%;
	max-height: 82px;
	margin-top: 2%;
	margin-bottom: 2%;
}

@media (max-height: 400px) {
	.img {
		width: 20px;
	}
}

.goBackImage {
	width: 50px;
	height: 50px;
	cursor: pointer;
}

.disabledItem {
	pointer-events: none;
	color: #d8d8d8;
}