@import "../../../styles/variables";

.source-panel-container {
    transform: translate(-500px,10px);
    min-width: 330px;
    
    &.visible {
        transform: translate(120px,10px);
    }

    .map-source-row {
        padding: 24px 12px 24px 12px;
        display:flex;
        justify-content: space-around;
    }
    .map-source-box{
        display:flex;
        flex-direction: column;
        align-items: center;
        .map-source-icon{
            border-radius:8px;
            box-shadow: 1px 1px 1px 1px black;
            box-sizing: border-box;
            width: 65px;
            height: 65px;
            margin-bottom:10px;
            overflow: hidden;
            img {
                max-width:200%;
            }
            &.selected{
                border: 3px solid map-get($colors, secondary);
            }
        }
        .map-source-description{
            font-size: map-get($fonts, regular);
            font-weight: normal;
            text-align: center;
        }
    }
}
