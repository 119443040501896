$mainMenuWidth: 100px;
$colors: (
    dark-gradient: #00bbf7,
    light-gradient: #a3f1ff,
    main: #25334b,
    secondary: #3ac5f2,
    main-dark: #131e30,
    panel-border: rgba(0,0,0,0.12)
);
$fonts: (
    large: 1.4rem,
    regular: 1rem,
    small: 0.8rem
);

%center {
    display:flex;
    justify-content: center;
    align-items: center;
};
