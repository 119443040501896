@import "../../../styles/variables";

.adas-layers-panel-container {
    transform: translate(-500px, 92px);
    min-width: 410px;
    
    &.visible {
        transform: translate(120px,92px);
    }

    .adas-layers-table {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        .create-new-layer-box {
            width: 100%;
            color: white;
            margin-top: 23px;
            margin-bottom: 23px;
            margin-left: 36px;
            .create-new-layer {
                font-size: map-get($fonts, regular);
                font-weight: normal;
                text-align: left;
            }
        }
        .adas-layers-list {
            max-height: 6 * 60px;
            overflow-y: auto;
        }

        .adas-layers-row {
            display:flex;
            flex-direction: row;
            align-items: center;
            border: solid 1px #979797;
            height: 60px;
            background-color: #34435e;
            cursor: pointer;

            .full-width {
                display:flex;
                flex-direction: row;
                width: 100%;
                margin-left: 20px;
                align-items: center;
                .p-checkbox-box, .p-checkbox-box.p-component {
                    border: solid 1px #ffffff;
                    background-color: #131c2c;
                    width: 25px;
                    height: 25px;
                    outline: 0 none !important;
                    box-shadow: none !important;
                }
                .p-checkbox-icon {
                    margin-top: 2px;
                }

                .adas-layers-description {
                    width: 100%;
                    cursor: pointer;
                    align-items: center;
                    font-size: map-get($fonts, regular);
                    font-weight: normal;
                    text-align: left;
                    margin-left: 25px;
                }
            }

            .adas-layers-icon-box {
                background-color: #23324d;
                margin-left: auto;
                height: 100%;
                width: 61px;

                .adas-layers-icon {
                    width: 22px;
                    height: 23px;
                }

            }

            .adas-layers-icon-disabled {
                background-color: #23324d;
                width: 61px;
                align-items: center;
                margin-left: auto;
                height: 100%;

                img {
                    opacity: 0.5;
                }

                .adas-layers-icon {
                    width: 22px;
                    height: 23px;
                }
            }

        }

    }
}
