@font-face {
  font-family: 'icomoon';
  src: url(../../static/media/icomoon.40917a9f.eot);
  src: url(../../static/media/icomoon.40917a9f.eot#iefix) format("embedded-opentype"), url(../../static/media/icomoon.9e6f3603.ttf) format("truetype"), url(../../static/media/icomoon.18eeaf91.woff) format("woff"), url(../../static/media/icomoon.d83cd818.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* plus */
.icon-plus:before {
  content: "\E902"; }

/* reload */
.icon-reload:before {
  content: "\E90A"; }

/* re-center" */
.icon-re-center:before {
  content: "\E90B"; }

/* align-north */
.icon-align-north:before {
  content: "\E90C"; }

/* minus */
.icon-minus:before {
  content: "\E90D"; }

/* sensors */
.icon-sensors:before {
  content: "\E90F"; }

/* track-gradient */
.icon-track-gradient:before {
  content: "\E904"; }

/* track-line */
.icon-track-straight:before {
  content: "\E905"; }

.icon-heatmap-cursor .path1:before {
  content: "\E907"; }

.icon-heatmap-cursor .path2:before {
  content: "\E908"; }

/* track-dots */
.icon-track-dots:before {
  content: "\E909"; }

/* settings */
.icon-settings:before {
  content: "\E911"; }

/* commands */
.icon-commands:before {
  content: "\E912"; }

/* bookmark */
.icon-bookmark:before {
  content: "\E913"; }

/* video */
.icon-video:before {
  content: "\E914"; }

/* search */
.icon-search:before {
  content: "\E915"; }

/* layers */
.icon-layers:before {
  content: "\E916"; }

html {
  font-size: 20px;
  height: 100vh;
  overflow: hidden; }

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: Montserrat, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

li {
  list-style: none; }

ul {
  padding: 0;
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0; }

.gradient-text {
  background: -webkit-linear-gradient(#a3f1ff, #00bbf7);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.is-active .gradient-text {
  background: -webkit-linear-gradient(white, #a3f1ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.panel-container {
  transition: 0.5s;
  position: absolute;
  z-index: 5000;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5), 0 0 2px 0 rgba(0, 0, 0, 0.5);
  background: #25334b;
  cursor: pointer; }
  .panel-container.visible {
    z-index: 10000;
    top: 0; }

:root {
    --breakpoint-mobile: 400px;
    --mainMenuWidth: 100px;
}

.app {
  display: flex;
  height: 100vh;
  overflow: hidden; }

/* width */
::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; }

.p-growl.p-component.p-growl-bottomleft {
  left: 130px;
  bottom: 40px;
  top: auto;
  height: 100px;
  width: 300px; }

@-webkit-keyframes blink {
  0% {
    opacity: 1; }
  70% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes blink {
  0% {
    opacity: 1; }
  70% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.MuiPaper-root {
    background-color: #2e3a4e !important;
}

.MuiTypography-colorTextSecondary {
    color: white !important;
}

.MuiButton-textPrimary {
    color: #ffffff !important;
}
.MuiPickersCalendarHeader-iconButton {
    background-color: transparent !important;
}

.MuiPickersDay-daySelected{
    border: 2px solid white !important;
    background-color: transparent !important;
}

.MuiButton-textPrimary:hover {
    background-color: #488ce6 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #131c2c !important;
}

.MuiIconButton-colorSecondary:hover {
    background-color: #488ce622 !important;
}

.MuiTypography-root {
    color: white !important;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: #488ce622 !important;
}

.MuiListItem-button:hover {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.MuiPickersClockNumber-clockNumberSelected {
    color: #2B3648 !important;
}

.date-time-picker-filter .MuiInputBase-input{
    color: white;
    font-size: 14px;
    font-weight: normal;
}

.filterbar-form-control-style-override .MuiSelect-selectMenu{
    color: white;
    font-size: 14px;
    font-weight: normal;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: white !important;
}

.MuiRadio-root {
    color: white !important;
}

.MuiMenuItem-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
video::-webkit-media-controls-fullscreen-button {
    display: none;
}

video::-webkit-media-controls-volume-slider {
    display: none;
}

video::-webkit-media-controls-mute-button {
    display: none;
}

.map-control-wrapper {
  position: absolute;
  right: 1.5vw;
  top: 5vh;
  width: 100px;
  background: transparent;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .map-control-wrapper > div {
    color: white;
    background: #131e30;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
    width: 65px;
    height: 65px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.12), 0 3px 5px rgba(0, 0, 0, 0.12);
    cursor: pointer; }
    .map-control-wrapper > div.small-round {
      width: 45px;
      height: 45px; }
    .map-control-wrapper > div.round {
      border-radius: 50%; }
      .map-control-wrapper > div.round.is-active {
        background: #00bbf7;
        border: 1px solid #25334b;
        box-shadow: inset 0 0 12px 2px #25334b; }
    .map-control-wrapper > div.north {
      transition: -webkit-transform 0.5s;
      transition: transform 0.5s;
      transition: transform 0.5s, -webkit-transform 0.5s; }
    .map-control-wrapper > div.zoom {
      height: 40px;
      width: 40px;
      margin-bottom: 0; }
      .map-control-wrapper > div.zoom:last-child {
        margin-top: 0;
        border-top: 1px solid; }
  .map-control-wrapper .icon-reload {
    font-size: 26px; }
  .map-control-wrapper .icon-align-north {
    font-size: 50px; }
  .map-control-wrapper .icon-re-center {
    font-size: 52px;
    font-weight: normal; }
  .map-control-wrapper .text-button-zoom {
    font-size: 40px;
    -webkit-transform: translate(0, -6px);
            transform: translate(0, -6px);
    font-weight: normal; }
  .map-control-wrapper .text-button-3d {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    -webkit-transform: translate(0, -1px);
            transform: translate(0, -1px); }
  .map-control-wrapper .icon-audio {
    height: 23px; }

.map-control-wrapper-multi-site {
  top: calc(5vh + 50px); }

.sites-selector-container {
  position: absolute;
  right: 3.5vw;
  top: 5vh;
  background: #131e30;
  opacity: 0.8;
  z-index: 1000;
  font-family: Mukta Mahee;
  padding-top: 5px;
  padding-bottom: 5px; }
  .sites-selector-container .MuiSelect-icon {
    color: white;
    margin-top: -3px; }
  .sites-selector-container .MuiSelect-select.MuiSelect-select {
    color: white;
    font-size: 16px;
    font-family: Mukta Mahee;
    font-weight: 300;
    margin-right: 5px;
    display: flex;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px; }
  .sites-selector-container .MuiIconButton-label {
    color: white;
    width: 15px;
    height: 3px; }

.site-label {
  color: white;
  font-size: 16px;
  font-family: Mukta Mahee;
  font-weight: 300; }

.site-selected-label {
  margin-left: 20px; }

.content-section implementation p-fluid .p-growl {
  position: fixed;
  width: 20em;
  z-index: 10000; }

.content-section implementation p-fluid .growl-bottomleft {
  left: 130px;
  bottom: 40px;
  top: auto;
  height: 100px; }

.menu {
  width: 100px;
  color: white; }
  @media (max-height: 400px) {
    .menu {
      width: 40px; } }

.menu-container {
  position: relative;
  z-index: 10000;
  height: 100vh;
  background: #25334b;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.5), 0 0 16px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center; }
  .menu-container ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    width: 100%; }
    .menu-container ul li.menu-item {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 10px;
      cursor: pointer;
      height: 80px; }
      @media (max-height: 400px) {
        .menu-container ul li.menu-item {
          height: 40px; } }
      .menu-container ul li.menu-item img {
        width: 62px;
        height: 62px; }
        @media (max-height: 400px) {
          .menu-container ul li.menu-item img {
            width: 34px;
            height: 34px; } }
      .menu-container ul li.menu-item.selected {
        background: #131e30; }
      .menu-container ul li.menu-item .icon-sensors {
        font-size: 42px; }
      .menu-container ul li.menu-item .icon-maps {
        height: 42px;
        width: 42px; }
      .menu-container ul li.menu-item .icon-layers {
        font-size: 42px; }
    .menu-container ul li.selected + li.border {
      display: none;
      margin-top: 5px; }
    .menu-container ul li.border {
      width: 20px;
      height: 1px;
      background: #131e30; }
  .menu-container .audio-button {
    cursor: pointer;
    color: white;
    position: absolute;
    bottom: 100px;
    width: calc(100px - 24px);
    height: calc(100px - 24px); }
    .menu-container .audio-button img {
      width: 100%; }

.panels {
  position: relative;
  height: 0;
  width: 0;
  z-index: 10; }

.sensor-display .heat-map-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }

.sensor-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px 24px 24px 24px;
  box-sizing: border-box; }
  .sensor-display:last-child {
    border-bottom: none; }
  .sensor-display header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px; }
    .sensor-display header h2 {
      margin: 0;
      font-size: 1.4rem;
      font-weight: normal; }
  .sensor-display .sensor-display-flags {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .sensor-display .sensor-display-flags .display-box {
      border-radius: 8px;
      box-shadow: 0 0 2px 1px white;
      background: #131e30;
      width: 65px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 75px;
      overflow: hidden;
      box-sizing: border-box;
      opacity: 0.7; }
      .sensor-display .sensor-display-flags .display-box > span {
        -webkit-transform: translate(10px, 0);
                transform: translate(10px, 0);
        font-weight: normal; }
        .sensor-display .sensor-display-flags .display-box > span.icon-track-dots {
          -webkit-transform: translate(10px, 0px);
                  transform: translate(10px, 0px);
          font-size: 65px; }
      .sensor-display .sensor-display-flags .display-box.disabled {
        opacity: 0.2; }
    .sensor-display .sensor-display-flags.visible .display-box:not(.disabled) {
      opacity: 1; }
    .sensor-display .sensor-display-flags.visible.dots .toggle-dots {
      border: 3px solid #3ac5f2;
      box-shadow: none; }
    .sensor-display .sensor-display-flags.visible.straight .toggle-straight {
      border: 3px solid #3ac5f2;
      box-shadow: none; }
    .sensor-display .sensor-display-flags.visible.gradient .toggle-gradient {
      border: 3px solid #3ac5f2;
      box-shadow: none; }
    .sensor-display .sensor-display-flags.visible.header-heat-map .toggle-header-heat-map {
      border: 3px solid #3ac5f2;
      box-shadow: none; }
  .sensor-display .heat-map-icon-wrapper {
    border-radius: 50%;
    width: 1px;
    height: 1px;
    box-shadow: 0 0 16px 10px white;
    font-size: 50px; }

.sensor-panel-container {
  -webkit-transform: translate(-500px, 330px);
          transform: translate(-500px, 330px);
  min-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start; }
  .sensor-panel-container.visible {
    -webkit-transform: translate(120px, 330px);
            transform: translate(120px, 330px); }

.source-panel-container {
  -webkit-transform: translate(-500px, 10px);
          transform: translate(-500px, 10px);
  min-width: 330px; }
  .source-panel-container.visible {
    -webkit-transform: translate(120px, 10px);
            transform: translate(120px, 10px); }
  .source-panel-container .map-source-row {
    padding: 24px 12px 24px 12px;
    display: flex;
    justify-content: space-around; }
  .source-panel-container .map-source-box {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .source-panel-container .map-source-box .map-source-icon {
      border-radius: 8px;
      box-shadow: 1px 1px 1px 1px black;
      box-sizing: border-box;
      width: 65px;
      height: 65px;
      margin-bottom: 10px;
      overflow: hidden; }
      .source-panel-container .map-source-box .map-source-icon img {
        max-width: 200%; }
      .source-panel-container .map-source-box .map-source-icon.selected {
        border: 3px solid #3ac5f2; }
    .source-panel-container .map-source-box .map-source-description {
      font-size: 1rem;
      font-weight: normal;
      text-align: center; }

.adas-layers-panel-container {
  -webkit-transform: translate(-500px, 92px);
          transform: translate(-500px, 92px);
  min-width: 410px; }
  .adas-layers-panel-container.visible {
    -webkit-transform: translate(120px, 92px);
            transform: translate(120px, 92px); }
  .adas-layers-panel-container .adas-layers-table {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between; }
    .adas-layers-panel-container .adas-layers-table .create-new-layer-box {
      width: 100%;
      color: white;
      margin-top: 23px;
      margin-bottom: 23px;
      margin-left: 36px; }
      .adas-layers-panel-container .adas-layers-table .create-new-layer-box .create-new-layer {
        font-size: 1rem;
        font-weight: normal;
        text-align: left; }
    .adas-layers-panel-container .adas-layers-table .adas-layers-list {
      max-height: 360px;
      overflow-y: auto; }
    .adas-layers-panel-container .adas-layers-table .adas-layers-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: solid 1px #979797;
      height: 60px;
      background-color: #34435e;
      cursor: pointer; }
      .adas-layers-panel-container .adas-layers-table .adas-layers-row .full-width {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-left: 20px;
        align-items: center; }
        .adas-layers-panel-container .adas-layers-table .adas-layers-row .full-width .p-checkbox-box, .adas-layers-panel-container .adas-layers-table .adas-layers-row .full-width .p-checkbox-box.p-component {
          border: solid 1px #ffffff;
          background-color: #131c2c;
          width: 25px;
          height: 25px;
          outline: 0 none !important;
          box-shadow: none !important; }
        .adas-layers-panel-container .adas-layers-table .adas-layers-row .full-width .p-checkbox-icon {
          margin-top: 2px; }
        .adas-layers-panel-container .adas-layers-table .adas-layers-row .full-width .adas-layers-description {
          width: 100%;
          cursor: pointer;
          align-items: center;
          font-size: 1rem;
          font-weight: normal;
          text-align: left;
          margin-left: 25px; }
      .adas-layers-panel-container .adas-layers-table .adas-layers-row .adas-layers-icon-box {
        background-color: #23324d;
        margin-left: auto;
        height: 100%;
        width: 61px; }
        .adas-layers-panel-container .adas-layers-table .adas-layers-row .adas-layers-icon-box .adas-layers-icon {
          width: 22px;
          height: 23px; }
      .adas-layers-panel-container .adas-layers-table .adas-layers-row .adas-layers-icon-disabled {
        background-color: #23324d;
        width: 61px;
        align-items: center;
        margin-left: auto;
        height: 100%; }
        .adas-layers-panel-container .adas-layers-table .adas-layers-row .adas-layers-icon-disabled img {
          opacity: 0.5; }
        .adas-layers-panel-container .adas-layers-table .adas-layers-row .adas-layers-icon-disabled .adas-layers-icon {
          width: 22px;
          height: 23px; }

.edit-layer-mode-panel-container {
  -webkit-transform: translate(-500px, 175px);
          transform: translate(-500px, 175px);
  min-width: 410px;
  height: 450px; }
  .edit-layer-mode-panel-container.visible {
    -webkit-transform: translate(120px, 175px);
            transform: translate(120px, 175px); }
  .edit-layer-mode-panel-container .edit-mode-header {
    cursor: none;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #485669; }
    .edit-layer-mode-panel-container .edit-mode-header .title-box {
      display: flex;
      margin-left: 30px;
      align-items: center; }
      .edit-layer-mode-panel-container .edit-mode-header .title-box .title {
        font-size: 0.8rem;
        font-weight: normal;
        text-align: left;
        padding-top: 6px;
        padding-bottom: 6px; }
    .edit-layer-mode-panel-container .edit-mode-header .x-icon-box {
      display: flex;
      cursor: pointer;
      margin-left: auto;
      height: 100%;
      align-items: center;
      padding-left: 30px; }
      .edit-layer-mode-panel-container .edit-mode-header .x-icon-box .x-icon {
        width: 12px;
        height: 12px;
        margin-right: 30px; }
  .edit-layer-mode-panel-container .edit-layer-mode-container {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100% - 270px); }
    .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-footer {
      display: flex;
      flex-direction: row;
      height: calc(100% - 60px);
      align-items: flex-end; }
      .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-footer .edit-mode-footer-row {
        width: 100%;
        cursor: pointer;
        height: 60px;
        display: flex;
        flex-direction: row;
        background-color: #0e1724;
        border-radius: 5px;
        border: solid 2px rgba(255, 255, 255, 0.24); }
        .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-footer .edit-mode-footer-row .delete-title-box {
          display: flex;
          align-items: center; }
          .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-footer .edit-mode-footer-row .delete-title-box .delete-title {
            font-size: 1rem;
            font-weight: normal;
            text-align: left;
            padding-top: 6px;
            padding-bottom: 6px; }
        .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-footer .edit-mode-footer-row .delete-icon-box {
          display: flex;
          cursor: pointer;
          margin-left: 30px;
          height: 100%;
          width: 40px;
          align-items: center; }
          .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-footer .edit-mode-footer-row .delete-icon-box .delete-icon {
            width: 20px;
            height: 20px; }
    .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-title {
      display: flex;
      flex-direction: column;
      height: 60px;
      justify-content: space-around;
      margin-top: 40px; }
      .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-title .edit-title-box {
        display: flex;
        flex-direction: row;
        font-weight: normal;
        text-align: left;
        background-color: #25334b;
        border: none; }
        .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-title .edit-title-box .p-inputtext {
          width: 100%;
          color: #ffffff;
          font-size: 1rem;
          border: none;
          background: rgba(0, 0, 0, 0);
          padding: 0.3em; }
        .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-title .edit-title-box.disabled {
          font-size: 0.8rem;
          color: #979797; }
      .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-title .edit-title-icon-box {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        margin-left: auto;
        align-items: center; }
        .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-title .edit-title-icon-box .edit-title-icon {
          width: 18px;
          height: 18px; }
          .edit-layer-mode-panel-container .edit-layer-mode-container .edit-mode-title .edit-title-icon-box .edit-title-icon.disabled {
            opacity: 0.5; }
    .edit-layer-mode-panel-container .edit-layer-mode-container .edit-title-line {
      height: 1px;
      border: solid 0.5px #81a6e7;
      margin-top: 10px;
      margin-bottom: 30px; }
    .edit-layer-mode-panel-container .edit-layer-mode-container .draw-mode-table {
      display: flex;
      flex-direction: column; }
      .edit-layer-mode-panel-container .edit-layer-mode-container .draw-mode-table .draw-mode-row {
        width: 100%;
        cursor: pointer;
        height: 60px;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        background-color: #33425d;
        margin-bottom: 13px; }
        .edit-layer-mode-panel-container .edit-layer-mode-container .draw-mode-table .draw-mode-row.selected {
          border: solid 2px #81a6e7;
          background-color: #465b82; }
        .edit-layer-mode-panel-container .edit-layer-mode-container .draw-mode-table .draw-mode-row .draw-mode-title-box {
          display: flex;
          align-items: center; }
          .edit-layer-mode-panel-container .edit-layer-mode-container .draw-mode-table .draw-mode-row .draw-mode-title-box .draw-mode-title {
            font-size: 1rem;
            font-weight: normal;
            text-align: left;
            padding-top: 6px;
            padding-bottom: 6px; }
        .edit-layer-mode-panel-container .edit-layer-mode-container .draw-mode-table .draw-mode-row .draw-mode-icon-box {
          display: flex;
          cursor: pointer;
          margin-left: 30px;
          height: 100%;
          width: 40px;
          align-items: center; }
          .edit-layer-mode-panel-container .edit-layer-mode-container .draw-mode-table .draw-mode-row .draw-mode-icon-box .draw-mode-icon {
            width: 20px;
            height: 20px; }

.edit-feature-mode-panel-container {
  -webkit-transform: translate(-500px, 175px);
          transform: translate(-500px, 175px);
  min-width: 410px;
  height: 250px; }
  .edit-feature-mode-panel-container.visible {
    -webkit-transform: translate(120px, 175px);
            transform: translate(120px, 175px); }
  .edit-feature-mode-panel-container .edit-mode-header {
    cursor: none;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #485669; }
    .edit-feature-mode-panel-container .edit-mode-header .title-box {
      display: flex;
      margin-left: 30px;
      align-items: center; }
      .edit-feature-mode-panel-container .edit-mode-header .title-box .title {
        font-size: 0.8rem;
        font-weight: normal;
        text-align: left;
        padding-top: 6px;
        padding-bottom: 6px; }
    .edit-feature-mode-panel-container .edit-mode-header .x-icon-box {
      display: flex;
      cursor: pointer;
      margin-left: auto;
      height: 100%;
      align-items: center;
      padding-left: 30px; }
      .edit-feature-mode-panel-container .edit-mode-header .x-icon-box .x-icon {
        width: 12px;
        height: 12px;
        margin-right: 30px; }
  .edit-feature-mode-panel-container .edit-feature-mode-container {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100% - 270px); }
    .edit-feature-mode-panel-container .edit-feature-mode-container .edit-mode-footer {
      display: flex;
      flex-direction: row;
      height: calc(100% - 60px); }
      .edit-feature-mode-panel-container .edit-feature-mode-container .edit-mode-footer .edit-mode-footer-row {
        width: 100%;
        cursor: pointer;
        height: 60px;
        display: flex;
        flex-direction: row;
        background-color: #0e1724;
        border-radius: 5px;
        border: solid 2px rgba(255, 255, 255, 0.24); }
        .edit-feature-mode-panel-container .edit-feature-mode-container .edit-mode-footer .edit-mode-footer-row .delete-title-box {
          display: flex;
          align-items: center; }
          .edit-feature-mode-panel-container .edit-feature-mode-container .edit-mode-footer .edit-mode-footer-row .delete-title-box .delete-title {
            font-size: 1rem;
            font-weight: normal;
            text-align: left;
            padding-top: 6px;
            padding-bottom: 6px; }
        .edit-feature-mode-panel-container .edit-feature-mode-container .edit-mode-footer .edit-mode-footer-row .delete-icon-box {
          display: flex;
          cursor: pointer;
          margin-left: 30px;
          height: 100%;
          width: 40px;
          align-items: center; }
          .edit-feature-mode-panel-container .edit-feature-mode-container .edit-mode-footer .edit-mode-footer-row .delete-icon-box .delete-icon {
            width: 20px;
            height: 20px; }
    .edit-feature-mode-panel-container .edit-feature-mode-container .title {
      display: flex;
      flex-direction: column;
      height: 60px;
      justify-content: space-around;
      margin-top: 40px; }
    .edit-feature-mode-panel-container .edit-feature-mode-container .title-line {
      height: 1px;
      border: solid 0.5px #81a6e7;
      margin-top: 12px;
      margin-bottom: 30px; }

.create-new-layer-panel-container {
  -webkit-transform: translate(-500px, 175px);
          transform: translate(-500px, 175px);
  min-width: 410px;
  height: 250px;
  display: flex;
  flex-direction: column; }
  .create-new-layer-panel-container.visible {
    -webkit-transform: translate(120px, 175px);
            transform: translate(120px, 175px); }
  .create-new-layer-panel-container .create-new-layer-header {
    cursor: none;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #485669; }
    .create-new-layer-panel-container .create-new-layer-header .title-box {
      display: flex;
      margin-left: 30px;
      align-items: center; }
      .create-new-layer-panel-container .create-new-layer-header .title-box .title {
        font-size: 0.8rem;
        font-weight: normal;
        text-align: left;
        padding-top: 6px;
        padding-bottom: 6px; }
    .create-new-layer-panel-container .create-new-layer-header .x-icon-box {
      display: flex;
      cursor: pointer;
      margin-left: auto;
      height: 100%;
      align-items: center;
      padding-left: 30px; }
      .create-new-layer-panel-container .create-new-layer-header .x-icon-box .x-icon {
        width: 12px;
        height: 12px;
        margin-right: 30px; }
  .create-new-layer-panel-container .create-new-layer-container {
    margin: 20px 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
  .create-new-layer-panel-container .create-new-layer-input {
    align-items: flex-start;
    width: 100%;
    cursor: pointer;
    height: 60px; }
    .create-new-layer-panel-container .create-new-layer-input .p-inputtext {
      width: 50%;
      color: #ffffff;
      border: 0 solid rgba(0, 0, 0, 0);
      background: rgba(0, 0, 0, 0);
      padding: 0.3em; }
    .create-new-layer-panel-container .create-new-layer-input .title-line {
      width: 50%;
      height: 1px;
      border: solid 0.5px #81a6e7;
      margin-top: 10px; }
  .create-new-layer-panel-container .create-new-layer-footer {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    cursor: none; }
    .create-new-layer-panel-container .create-new-layer-footer .create-new-layer-footer-row {
      width: 100%;
      height: 60px;
      cursor: not-allowed;
      display: flex;
      align-items: center;
      background-color: #33425d;
      border-radius: 4px; }
      .create-new-layer-panel-container .create-new-layer-footer .create-new-layer-footer-row .create-new-layer-button {
        background-color: #33425d;
        border: none;
        width: 100%;
        font-size: 1rem;
        font-weight: normal;
        text-align: left;
        padding-left: 15px; }
        .create-new-layer-panel-container .create-new-layer-footer .create-new-layer-footer-row .create-new-layer-button.disabled {
          opacity: 0.5; }
        .create-new-layer-panel-container .create-new-layer-footer .create-new-layer-footer-row .create-new-layer-button.enabled {
          cursor: pointer;
          border: solid 2px #81a6e7;
          background-color: #465b82; }

.map-parent {
  width: calc(100%);
  box-sizing: content-box;
  height: 100vh; }

#map-container {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #0c1334; }

.status-bar {
  position: absolute;
  bottom: 0;
  left: 100px; }
  .status-bar ul {
    z-index: 5000;
    height: 36px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background: rgba(37, 51, 75, 0.8);
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-around; }
    .status-bar ul li {
      padding: 0.5em 5px;
      margin: 0 5px;
      font-size: 1em;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #d0d0d0; }

.status-item {
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: space-around; }

.oval {
  width: 0.73em;
  height: 0.73em;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  margin: 0 5px; }

.alert-container {
  position: fixed;
  left: 50%;
  pointer-events: none;
  margin-top: 20px; }

.alert-item {
  -webkit-animation: show-alert 1.5s;
          animation: show-alert 1.5s;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.alert-item-const {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

@-webkit-keyframes show-alert {
  0% {
    opacity: 1; }
  25% {
    opacity: 0; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes show-alert {
  0% {
    opacity: 1; }
  25% {
    opacity: 0; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.alert-rectangle {
  width: 718px;
  margin-left: -359px;
  height: 87px;
  border-radius: 43.5px;
  box-shadow: 0 12px 19px 0 rgba(71, 71, 52, 0.38);
  border: solid 4px rgba(37, 51, 75, 0.34);
  background-color: #eae26c; }

.zone-alert-color {
  background-color: #ff6665; }

.zone-alert-icon {
  height: 39px;
  object-fit: contain; }

.zone-alert-text {
  margin-left: 25px;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3b4f; }

.obstacle-alert-text {
  margin-left: 25px;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }

.no-alerts-color {
  background-color: lightgray; }

.no-alerts-icon {
  width: 0px;
  height: 51px;
  object-fit: contain; }

.no-alerts-text {
  margin-left: 0px;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3b4f; }

.lane-alert-color {
  background-color: #eae26c; }

.lane-alert-icon {
  width: 77px;
  height: 51px;
  object-fit: contain; }

.lane-alert-text {
  margin-left: 25px;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3b4f; }

.bottom {
  margin-top: 500px; }


.StatusAlert_backdrop__3QxBc {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 30;
    -webkit-animation: StatusAlert_slide-down__1YoVd 300ms ease-out forwards;
            animation: StatusAlert_slide-down__1YoVd 300ms ease-out forwards;
}

.StatusAlert_Rectangle__19a83 , .StatusAlert_RectangleVertical__28_1O {
    position: fixed;
    top: 10%;
    left: 20%;
    width: 60vw;
    border: solid 0.5px #fff;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.5), 0 0 16px 0 rgba(0, 0, 0, 0.5);
    background-color: #1f2641;
    display:block;
}
.StatusAlert_Connecting__9jEDZ, .StatusAlert_ConnectingVertical__QKOaL, .StatusAlert_Notification__1TsWo, .StatusAlert_NotificationVertical__C6OKy  {
    position: fixed;
    top: 20%;
    left: 20%;
    width: 60vw;
    border: solid 0.5px #000000;
    border-radius: 10px;
    background-color: #FF7100;
    display:block;
}
.StatusAlert_Connecting__9jEDZ {
    top: 40%;
    height: 20%;

}
.StatusAlert_ConnectingVertical__QKOaL  {
    top:45vh;
}
.StatusAlert_RectangleVertical__28_1O{
    top: 35%;
    width:70%;
    left: 16%;
}

.StatusAlert_title__mMKo4 , .StatusAlert_text__2bRgG , .StatusAlert_ConnectingContent__ZhyYO, .StatusAlert_ConnectingContentVertical__213xG, .StatusAlert_NotificationContent__ngn-0, .StatusAlert_NotificationContentVertical__3lAmA, .StatusAlert_verticalTitle__ZvRnE, .StatusAlert_verticalText__32ENw{
    margin: 2% 20%;
    font-family: Mukta Mahee;
    font-size: 2.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width:60%;
}
.StatusAlert_verticalTitle__ZvRnE{
    font-size: clamp(1rem, 10vw, 2rem);
}
.StatusAlert_verticalText__32ENw{
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: clamp(1.25rem, 3vw, 1.75rem); }
.StatusAlert_text__2bRgG {
    margin: 5% 20%;
    font-size: 2rem;
    margin-bottom: 5%;

}
.StatusAlert_ConnectingContent__ZhyYO ,.StatusAlert_ConnectingContentVertical__213xG{
    display: inline-flex;
    margin-top: -8vh;
}
.StatusAlert_ConnectingTitle__wEhjv, .StatusAlert_ConnectingTitleVertical__12Oic{
    margin-left: 5%;
}
.StatusAlert_ConnectingContentVertical__213xG{
    margin-left:  20%;
    margin-top: auto;
    font-size: clamp(1.5rem, 3vw, 1.75rem);
}
.StatusAlert_LoadingIcon__3kJin{
    min-width: 2rem;
    margin: auto;
    width: 1em;
}
.StatusAlert_Notification__1TsWo, .StatusAlert_NotificationVertical__C6OKy  {
    color: #ffffff;
    z-index: 30;
    display: inherit;
    border: solid 0.5px #000000;
    width: 37.5vw;
    border-radius: 2px;
    box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.24);
    background-image: linear-gradient(to bottom, #000 1%, #393939);

}
.StatusAlert_Notification__1TsWo {
    top: 4%;
    height: 8%;
    left: 1%;
}

.StatusAlert_NotificationVertical__C6OKy  {
    top: 92%;
    height: 5%;
    width: 58vw;
}
 .StatusAlert_NotificationContent__ngn-0 ,.StatusAlert_NotificationContentVertical__3lAmA{
    display: block;
    font-size: clamp(0.5rem, 3vw, 1.25rem);
    margin-left: 17%;
    width: 33vw;
    text-align: left;
     margin: 2% -2%;
}

.StatusAlert_NotificationContent__ngn-0{
    margin-left: 2%;
    font-size: clamp(0.5rem, 3vw, 1rem);
}

.StatusAlert_NotificationContentVertical__3lAmA{
    margin-left: 2%;
    margin-top: 3.5%;
    font-size: clamp(0.5rem, 3vw, 1.25rem);
    width: 51vw;
    text-align: left;
}

.StatusAlert_NotificationIcon__1q8FE {
    min-width: 1.25rem;
    margin-left: 0.5vw;
    width: 1em;
}

.Dashboard_dashboard__1sJus {
	background-color: #414c60;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	grid-gap: 15px;
	gap: 15px;
}

.Dashboard_options__1aCRK {
	margin-left: 30px;
}

.Dashboard_charts__my2uC {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.SiteChart_body__1RTnv {
	align-self: flex-start;
	display: grid;
	width: calc(100vw - 60px);
	height: calc(100vh - 170px);
	grid-template-columns: 100%; 
	grid-template-rows: 1fr 1fr 1fr;
	grid-gap: 15px;
	gap: 15px;
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 15px;
}

.SiteChart_chart__1iLWv {
	background-color: #d8d8d8;
	border: 1px #979797;
}

.OverallCharts_body__1aaft {
	align-self: flex-start;
	display: grid;
	width: calc(100vw - 60px);
	height: calc(100vh - 195px);
	grid-template-columns: 50% 50%; 
	grid-template-rows: 50% 50%;
	grid-gap: 15px;
	gap: 15px;
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 15px;
}

.OverallCharts_chart__2ymm8 {
	background-color: #d8d8d8;
	border: 1px #979797;
}

.OverallCharts_wideChart__3GyRe {
	grid-column: span 2;
}

.Navigator_navigator__Kv5_Z {
	background-color: #414c60;
	display: flex;
}

.Navigator_navigation__1wQa4 {
	height: 60px;
	background-color: #414c60;
	display: grid;
	grid-template-columns: 45% 45% 10%;
	flex-grow: 1;
}

.Navigator_gridItem__37IET {
	font-size: 20px;
	text-align: center;
	color: #ffffff;
	font-family: Mukta Mahee;
	background: #414c60;
	border-color: transparent;
}

.Navigator_indicator__3GTM5 {
	width: calc(100% + 45px);
	height: 3px;
	margin-top: 16px;
	margin-left: -45px;
	background-image: linear-gradient(
		to right,
		#3272b5,
		#691bbe 46%,
		#ac317f 98%
	);
}

.Navigator_titleContent__37zFg {
	display: block;
	margin-top: 2%;
	margin-bottom: 2%;
	margin-left: -10px;
}

.Navigator_title__1WgTJ {
	width: 158px;
	height: 40px;
	margin-top: -60px;
	font-family: Mukta Mahee;
	margin-left: 60px;
	font-size: 24px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: rgba(255, 255, 255, 0.85);
}

.Navigator_text__gptUf {
	width: 7rem;
	height: 27px;
	margin-top: -1.5vh;
	margin-left: 50px;
	font-family: Mukta Mahee;
	font-size: 16px;
	font-weight: 200;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: rgba(255, 255, 255, 0.85);
}

.Navigator_titleBackground__3uGg- {
	width: 224px;
	height: 60px;
	padding: 7px 170px 7px 14px;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
		0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
	background-color: #343d4e;
	-webkit-clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 0% 100%);
	        clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 0% 100%);
}

.Navigator_ceptionLogo__1RIiC {
	max-width: 100%;
	max-height: 82px;
	margin-top: 2%;
	margin-bottom: 2%;
}

@media (max-height: 400px) {
	.Navigator_img__1Enej {
		width: 20px;
	}
}

.Navigator_goBackImage__2fBNb {
	width: 50px;
	height: 50px;
	cursor: pointer;
}

.Navigator_disabledItem__3mA2g {
	pointer-events: none;
	color: #d8d8d8;
}
.Options_options__3W7iZ {
	color: white;
	display: grid;
	grid-template-columns: 25% 25% 50%;	
	margin-left: 30px;
}

.Options_formControl__2zNls {
	margin: 20px;
	color: white;
}

.Options_select__1mr4z {
	width: auto;
	color: white;
	margin-right: 10px;
}

option {
	font-family: Mukta Mahee;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #fff !important;
}

.Options_datesRange__175ct {
	font-family: Mukta Mahee;
	font-size: 21px;
	font-weight: bold;
}

.Options_selectText__3NK9z {
	font-family: Mukta Mahee;
	font-size: 14px;
	color: white;
	font-weight: normal;
}

