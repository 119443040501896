@import "../../../styles/variables";

.edit-layer-mode-panel-container {
    transform: translate(-500px, 175px);
    min-width: 410px;
    height: 450px;

    &.visible {
        transform: translate(120px,175px);
    }

    .edit-mode-header {
        cursor: none;
        height: 60px;
        display:flex;
        flex-direction: row;
        align-items: center;
        background-color: #485669;
        .title-box {
            display:flex;
            margin-left: 30px;
            align-items: center;
            .title {
                font-size: map-get($fonts, small);
                font-weight: normal;
                text-align: left;
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
        .x-icon-box {
            display:flex;
            cursor: pointer;
            margin-left: auto;
            height: 100%;
            align-items: center;
            padding-left: 30px;
            .x-icon {
                width: 12px;
                height: 12px;
                margin-right: 30px;
            }
        }
    }

    .edit-layer-mode-container {
        width: 350px;
        margin-left: auto;
        margin-right: auto;
        height: calc(100% - 270px);

        .edit-mode-footer {
            display: flex;
            flex-direction: row;
            height: calc(100% - 60px);
            align-items: flex-end;
            .edit-mode-footer-row {
                width: 100%;
                cursor: pointer;
                height: 60px;
                display: flex;
                flex-direction: row;
                background-color: #0e1724;
                border-radius: 5px;
                border: solid 2px rgba(255, 255, 255, 0.24);
                .delete-title-box {
                    display: flex;
                    align-items: center;
                    .delete-title {
                        font-size: map-get($fonts, regular);
                        font-weight: normal;
                        text-align: left;
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }
                }
                .delete-icon-box {
                    display:flex;
                    cursor: pointer;
                    margin-left: 30px;
                    height: 100%;
                    width: 40px;
                    align-items: center;
                    .delete-icon {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .edit-mode-title {
            display: flex;
            flex-direction: column;
            height: 60px;
            justify-content: space-around;
            margin-top: 40px;
            .edit-title-box {
                display:flex;
                flex-direction: row;
                font-weight: normal;
                text-align: left;
                background-color: #25334b;
                border: none;
                .p-inputtext {
                    width: 100%;
                    color: #ffffff;
                    font-size: map-get($fonts, regular);
                    border: none;
                    background: rgba(0,0,0,0);
                    padding: 0.3em;
                }
                &.disabled {
                    font-size: map-get($fonts, small);
                    color: #979797;
                }
            }
            .edit-title-icon-box {
                display:flex;
                flex-direction: row;
                cursor: pointer;
                margin-left: auto;
                align-items: center;
                .edit-title-icon {
                    width: 18px;
                    height: 18px;

                    &.disabled {
                      opacity: 0.5;
                    }
                }
            }
        }
        .edit-title-line {
            height: 1px;
            border: solid 0.5px #81a6e7;
            margin-top: 10px;
            margin-bottom: 30px;
        }

        .draw-mode-table {
            display: flex;
            flex-direction: column;
            .draw-mode-row {
                width: 100%;
                cursor: pointer;
                height: 60px;
                display: flex;
                flex-direction: row;
                border-radius: 4px;
                background-color: #33425d;
                margin-bottom: 13px;
                &.selected {
                    border: solid 2px #81a6e7;
                    background-color: #465b82;
                }
                .draw-mode-title-box {
                    display: flex;
                    align-items: center;
                    .draw-mode-title {
                        font-size: map-get($fonts, regular);
                        font-weight: normal;
                        text-align: left;
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }
                }
                .draw-mode-icon-box {
                    display:flex;
                    cursor: pointer;
                    margin-left: 30px;
                    height: 100%;
                    width: 40px;
                    align-items: center;
                    .draw-mode-icon {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}
