.alert-container {
  position: fixed;
  left: 50%;
  pointer-events: none;
  margin-top: 20px;
}

.alert-item {
  animation: show-alert 1.5s;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.alert-item-const {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@keyframes show-alert {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.alert-rectangle {
  width: 718px;
  margin-left: -359px;
  height: 87px;
  border-radius: 43.5px;
  box-shadow: 0 12px 19px 0 rgba(71, 71, 52, 0.38);
  border: solid 4px rgba(37, 51, 75, 0.34);
  background-color: #eae26c;
}

.zone-alert-color {
  background-color: #ff6665;
}

.zone-alert-icon {
  //width: 47px;
  height: 39px;
  object-fit: contain;
}

.zone-alert-text {
  margin-left: 25px;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3b4f;
}

.obstacle-alert-text {
  margin-left: 25px;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.no-alerts-color {
  background-color: lightgray;
}

.no-alerts-icon {
  width: 0px;
  height: 51px;
  object-fit: contain;
}

.no-alerts-text {
  margin-left: 0px;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3b4f;
}

.lane-alert-color {
  background-color: #eae26c;
}

.lane-alert-icon {
  width: 77px;
  height: 51px;
  object-fit: contain;
}

.lane-alert-text {
  margin-left: 25px;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3b4f;
}

.bottom {
  margin-top: 500px;
}

