@import "../../../styles/variables";

.map-control-wrapper {
	position: absolute;
	right: 1.5vw;
	top: 5vh;
	width: 100px;
	background: transparent;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
	& > div {
        color: white;
        background: map-get($colors, main-dark);
        opacity: 0.8;
        display:flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0px;
        width: 65px;
        height: 65px;
        box-shadow: 0 0 5px map-get($colors,  panel-border),0 3px 5px map-get($colors, panel-border) ;
        cursor: pointer;
        &.small-round {
            width:45px;
            height: 45px;
        }
        &.round {
			border-radius: 50%;
			&.is-active{
				background: map-get($colors, dark-gradient);
				border: 1px solid map-get($colors, main);
				box-shadow: inset 0 0 12px 2px map-get($colors, main);
			}
        }
        &.north {
            transition: transform 0.5s;
        }
		&.zoom {
            height: 40px;
            width: 40px;
            margin-bottom:0;
            &:last-child{
                margin-top:0;
                border-top:1px solid map-get($colors, control-button-border);
            }
		}

    }
    // @todo change all font's to in ram , and also padding and margin and also add min values
    .icon-reload{
        font-size: 26px;
    }
    .icon-align-north{
        font-size: 50px;
    }
    .icon-re-center{
        font-size:52px;
        font-weight: normal;
    }
    .text-button-zoom {
        font-size:40px;
        transform: translate(0, -6px);
        font-weight: normal;
    }
    .text-button-3d {
        text-transform: uppercase;
        font-size:20px;
        font-weight: bold;
        transform: translate(0, -1px);
    }
    .icon-audio {
        height: 23px;
    }
    
}

.map-control-wrapper-multi-site {
    top: calc(5vh + 50px);
}

.sites-selector-container {
    position: absolute;
	right: 3.5vw;
	top: 5vh;
	background: #131e30;
    opacity: 0.8;
    z-index: 1000;
    font-family: Mukta Mahee;
    padding-top: 5px;
    padding-bottom: 5px;

    .MuiSelect-icon {
        color: white;
        margin-top: -3px;
    }
    .MuiSelect-select.MuiSelect-select {
        color: white;
        font-size: 16px;
        font-family: Mukta Mahee;
        font-weight: 300;
        margin-right: 5px;
        display: flex;
        align-items: center;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .MuiIconButton-label {
        color: white;
        width: 15px;
        height: 3px;    
    }
}

.site-label {
    color: white;
    font-size: 16px;
    font-family: Mukta Mahee;
    font-weight: 300;
}

.site-selected-label {
    margin-left: 20px;
}
